import "./App.css";
import backgroundImage from "./mobile.png";
import { useState, useEffect } from "react";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function App() {
  const { height, width } = useWindowDimensions();

  ///width < 700

  return (
    <div>
      <div
        className="z1000"
        style={
          width < 700
            ? {
                backgroundImage: `url(${backgroundImage})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                minWidth: "150px",
                minHeight: "250px",
                height: "100vh",
                width: "100%",
              }
            : {}
        }
      >
        <img src="./icon.png" alt="" width={40} />
        <h1 className="coming-soon-text">COMING SOON </h1>
        <img style={{
          width: 220
        }} src="./logo.png" alt="" />
        <div className="address-section">
          <div className="left">
            <p>
              <b>SHOWROOM</b>
            </p>
            <p> +976 98992184 </p>
            <p>REGIS PLACE TOWER </p>
            <p>
              7th Floor , Chinggis Avenue – 33/ 2 15th Khoroo, Khan-Uul District
              Ulaanbaatar, Mongolia
            </p>
          </div>
          <div className="right">
            <p>
              <b>HEAD OFFICE</b>
            </p>
            <p> +976 99060064 </p>
            <p> enkhmend@choihainzan.com </p>
            <p>
              702 Capital Center, 4th Khoroo Juulchin Street, Chingeltei
              District Ulaanbaatar, Mongolia
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
